<template>
  <div id="view-admin-login-edit">
    <section>
      <b-loading
        :is-full-page="false"
        v-model="isLoading"
        :can-cancel="false"
      ></b-loading>

      <div class="level">
        <div class="level-left">
          <b-select
            v-model="perPage"
            :disabled="!isPaginated"
            class="level-item"
          >
            <option value="5">5 per page</option>
            <option value="10">10 per page</option>
            <option value="15">15 per page</option>
            <option value="20">20 per page</option>
          </b-select>
        </div>
        <div class="level-right">
          <a
            @click="onAddPermission()"
            class="button is-small is-success is-light"
            >Add</a
          >
        </div>
      </div>

      <b-table
        :data="permissions"
        :paginated="true"
        :per-page="perPage"
        :current-page.sync="currentPage"
        :show-detail-icon="true"
        :has-detailed-visible="hasTasks"
        detailed
        detail-key="id"
        striped
        default-sort="name"
        aria-next-label="Next page"
        aria-previous-label="Previous page"
        aria-page-label="Page"
        aria-current-label="Current page"
        icon-pack="fas"
      >
        <b-table-column field="Id" label="ID" width="30" numeric v-slot="props">
          {{ props.row.id }}
        </b-table-column>

        <b-table-column width="auto" label="Name" v-slot="props">
          {{ props.row.name }}
        </b-table-column>

        <b-table-column label="Tools" v-slot="props" sticky width="65">
          <span>
            <a
              @click="onDeletePermission(props.row)"
              class="button is-small is-danger is-light"
              >Delete</a
            >
          </span>
        </b-table-column>
        <template slot="detail" slot-scope="props">
          <ul>
            <li v-for="item in props.row.tasks" :key="item.roleTaskId">
              {{ item.task.name }} - {{ item.task.description }}
            </li>
          </ul>
        </template>
      </b-table>
    </section>

    <b-modal
      v-model="isAddDialogActive"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      :can-cancel="false"
      width="80%"
      scroll="keep"
      aria-role="dialog"
      aria-modal
    >
      <template #default>
        <add-permission-dialog
          :session="session"
          :user="user"
          :permissions="permissions"
          @onComplete="onAddPermissionComplete"
          @onCancel="onAddPermissionCancel"
        ></add-permission-dialog>
      </template>
    </b-modal>
  </div>
</template>
<script>
// add a modal for the role/task lists
import API from "@/Druware.Web.Shared/api/api";
import AddPermissionDialog from "./AddUserPermission";

export default {
  name: "view-admin-user-permissions",
  components: {
    AddPermissionDialog
  },
  props: {
    user: Object,
    session: Object
  },
  data() {
    return {
      isAddDialogActive: false,
      permissions: [],
      isLoading: false,
      disabled: false,
      perPage: 10,
      currentPage: 1,
      isPaginated: true
    };
  },
  watch: {
    user: function(val) {
      if (val != null) this.getRoles();
    }
  },
  methods: {
    getRoles() {
      var url = API.url.authentication.user;

      API.list(
        url + this.user.loginId + "/permissions",
        this,
        function(obj, data) {
          obj.permissions = data;
          obj.isLoading = false;
        },
        function(obj, err) {
          obj.showMessage("Unable to get permissions: " + err, "is-danger");
        }
      );
    },
    showMessage(msg, type = "is-danger") {
      this.$buefy.toast.open({
        duration: 5000,
        message: msg,
        position: "is-bottom",
        type: type
      });
    },
    hasTasks(obj) {
      return obj.hasTasks;
    },
    onAddPermission() {
      this.isAddDialogActive = true;
    },
    onAddPermissionComplete() {
      this.isAddDialogActive = false;
      this.getRoles();
    },
    onAddPermissionCancel() {
      this.isAddDialogActive = false;
    },
    onDeletePermission(obj) {
      var msg = 'Are you sure you want to <b>delete</b> "';
      msg += obj.name + " from this user? This action cannot be undone.'";

      this.$buefy.dialog.confirm({
        title: "Deleting Permission",
        message: msg,
        confirmText: "Delete Permission",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => {
          var t = obj.hasTasks ? "/permissions/role/" : "/permissions/task/";
          API.delete(
            API.url.authentication.user + this.user.loginId + t,
            this,
            obj.id,
            function(obj) {
              obj.getRoles();
              obj.$buefy.toast.open("Permission deleted!");
            },
            function(obj, err) {
              obj.showMessage(err);
            }
          );
        }
      });
    }
  },
  mounted() {
    this.isLoading = true;
  }
};
</script>
