<template>
  <div id="view-admin-login-add">
    <b-loading
      :is-full-page="false"
      v-model="isLoading"
      :can-cancel="false"
    ></b-loading>

    <b-card title="Add New User" :centered="true">
      <div class="media left">
        <div class="media-left is-hidden-touch">
          <figure class="image is-48x48">
            <img src="/icon/user_48x48.png" alt="Login Icon" />
          </figure>
        </div>
        <div class="media-content">
          <form action="" @submit="onSubmit">
            <div class="content">
              <p>
                Explain how this process works.
              </p>

              <b-field label="User Name" label-position="on-border">
                <b-input
                  ref="login-userName"
                  v-model="login.userName"
                  placeholder="Enter user name"
                  required
                  autofocus
                  :disabled="disabled"
                ></b-input>
                <!-- TODO: Add verification that the user name is not already taken -->
              </b-field>

              <b-field label="Last Name" label-position="on-border">
                <b-input
                  ref="login-lastName"
                  v-model="login.lastName"
                  placeholder="Enter last name"
                  required
                  autofocus
                  :disabled="disabled"
                ></b-input>
              </b-field>
              <b-field label="First Name" label-position="on-border">
                <b-input
                  v-model="login.firstName"
                  placeholder="Enter first name"
                  :disabled="disabled"
                ></b-input>
              </b-field>
              <b-field label="Middle Name" label-position="on-border">
                <b-input
                  v-model="login.middleName"
                  placeholder="Enter middle name"
                  :disabled="disabled"
                ></b-input>
              </b-field>
              <hr />
              <b-field label="e-mail" label-position="on-border">
                <b-input
                  v-model="login.email"
                  type="email"
                  placeholder="Enter email"
                  :disabled="disabled"
                ></b-input>
              </b-field>
              <b-field label="Phone" label-position="on-border">
                <b-input
                  v-model="login.phone"
                  type="tel"
                  placeholder="Enter  phone"
                  :disabled="disabled"
                ></b-input>
              </b-field>
              <hr />
            </div>
            <div class="level is-mobile">
              <div class="level-left"></div>
              <div class="level-right">
                <div class="field is-grouped">
                  <button
                    type="sumbit"
                    class="button is-link mr-3"
                    :disabled="disabled"
                  >
                    Create User
                  </button>
                  <button
                    type="cancel"
                    class="button is-link"
                    :disabled="disabled"
                    @click="onCancel"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import API from "@/Druware.Web.Shared/api/api";
import BCard from "@/Druware.Web.Shared/controls/Card";

export default {
  name: "view-admin-login-add",
  components: {
    BCard
  },
  props: {
    session: Object
  },
  data() {
    return {
      login: {},
      isLoading: false,
      disabled: false,
      error: null
    };
  },
  methods: {
    getModel() {
      API.model(
        API.url.authentication.user,
        this,
        function(obj, data) {
          obj.login = data;
          obj.isLoading = false;
        },
        function(obj, err) {
          obj.showMessage("Unable to get model: " + err, "is-danger");
        }
      );
    },
    onSubmit(e) {
      e.preventDefault();
      this.disabled = true;
      API.post(
        API.url.authentication.registration,
        this,
        this.login,
        function(obj, data) {
          obj.login = data;
          obj.disabled = false;
          obj.$emit("onComplete");
        },
        function(obj, err) {
          obj.disabled = false;
          obj.showMessage("Unable to create request: " + err, "is-danger");
        }
      );
    },
    onCancel() {
      this.$emit("onCancel");
    },

    showMessage(msg, type = "is-danger") {
      this.$buefy.toast.open({
        duration: 5000,
        message: msg,
        position: "is-bottom",
        type: type
      });
    }
  },
  mounted() {
    this.isLoading = true;
    this.getModel();
  }
};
</script>
