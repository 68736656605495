<template>
  <div id="add-user-permission-dialog">
    <b-card title="Add User Permissions" :centered="true">
      <form action="" @submit="onSubmit">
        <div class="media left">
          <div class="media-left is-hidden-touch">
            <figure class="image is-48x48">
              <img src="/icon/ulock_48x48.png" alt="Login Icon" />
            </figure>
          </div>
          <div class="media-content">
            <div class="content">
              <p>
                Select either a role, or a task to add to the user. This change
                will occur immediately, but can be removed from the User's
                Permissions list if added in error.
              </p>

              <div class="level">
                <div class="level-left">
                  <b-field
                    label="Role"
                    label-position="on-border"
                    class="level-item"
                  >
                    <b-select v-model="selectedRole" :disabled="disabled">
                      <option value="null" selected>( none )</option>
                      <option
                        v-for="t in availableRoles"
                        :value="t.roleId"
                        :key="t.roleId"
                      >
                        {{ t.name }}
                      </option>
                    </b-select>
                  </b-field>
                </div>
                <div class="level-item">or</div>
                <div class="level-right">
                  <b-field label="Task" label-position="on-border">
                    <b-select v-model="selectedTask" :disabled="disabled">
                      <option value="null" selected>( none )</option>
                      <option
                        v-for="t in availableTasks"
                        :value="t.taskId"
                        :key="t.taskId"
                      >
                        {{ t.name }}
                      </option>
                    </b-select>
                  </b-field>
                </div>
              </div>
              <hr />
            </div>
            <div class="level is-mobile">
              <div class="level-left"></div>
              <div class="level-right">
                <div class="field is-grouped">
                  <button
                    type="sumbit"
                    class="button is-link"
                    :disabled="disabled"
                  >
                    Add
                  </button>
                  <button
                    type="cancel"
                    class="button is-link is-light"
                    @click="onCancel"
                    :disabled="disabled"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </b-card>
  </div>
</template>

<script>
import API from "@/Druware.Web.Shared/api/api";
import BCard from "@/Druware.Web.Shared/controls/Card";

export default {
  name: "view-login",
  components: {
    BCard
  },
  data() {
    return {
      model: {},
      selectedRole: null,
      roles: [],
      selectedTask: null,
      tasks: [],
      disabled: false,
      permission_internal: null
    };
  },
  props: {
    session: {
      default: null,
      type: Object
    },
    user: {
      default: null,
      type: Object
    },
    permissions: {
      default: null,
      type: Array
    }
  },
  computed: {
    availableRoles: function() {
      var result = [];
      this.roles.forEach(function(role) {
        if (
          null ==
          this.permissions.find(
            element => element.hasTasks && element.id == role.roleId
          )
        ) {
          result.push(role);
          return;
        }
      }, this);
      return result;
    },
    availableTasks: function() {
      var result = [];
      this.tasks.forEach(function(task) {
        // if there is a permission that has this taskId as it's id and hasTasks is false, it should be skipped.
        var p = this.permissions.find(
          permission => !permission.hasTasks && permission.id == task.taskId
        );
        if (p != null) {
          return;
        }

        // now we've checked the tasks for matches, we need to handle the roles
        p = this.permissions.find(
          permission =>
            permission.hasTasks &&
            null !=
              permission.tasks.find(roletask => roletask.taskId == task.taskId)
        );
        if (p != null) {
          return;
        }

        result.push(task);
        return;
      }, this);
      return result;
    }
  },
  methods: {
    onSubmit(e) {
      e.preventDefault();
      this.disabled = true;

      // call the add method to add the task or role to the user
      this.model.loginId = this.user.loginId;
      if (this.selectedRole != null) {
        this.model.id = this.selectedRole;
        this.model.hasTasks = true;
      } else {
        this.model.id = this.selectedTask;
        this.model.hasTasks = false;
      }
      API.put(
        API.url.authentication.user + this.user.loginId + "/permissions",
        this,
        "",
        this.model,
        function(obj, data) {
          obj.permissions_internal = data;
          obj.$emit("onComplete");
        },
        function(obj, err) {
          obj.showMessage("Unable to add permission: " + err, "is-danger");
        }
      );

      this.disabled = false;
    },
    onCancel(e) {
      e.preventDefault();
      this.$emit("onCancel");
    },
    showMessage(msg) {
      this.$buefy.toast.open({
        duration: 5000,
        message: msg,
        position: "is-bottom",
        type: "is-danger"
      });
    }
  },
  mounted() {
    this.disabled = false;
    API.getUrl(
      API.url.authentication.user + "model/securable/permission",
      this,
      function(obj, data) {
        obj.model = data;
      },
      function(obj, err) {
        obj.showMessage("Unable to get permission model: " + err, "is-danger");
      }
    );
    API.list(
      API.url.authentication.picklist + "securable/roles",
      this,
      function(obj, data) {
        obj.roles = data;
      },
      function(obj, err) {
        obj.showMessage("Unable to get roles picklist: " + err, "is-danger");
      }
    );
    API.list(
      API.url.authentication.picklist + "securable/tasks",
      this,
      function(obj, data) {
        obj.tasks = data;
      },
      function(obj, err) {
        obj.showMessage("Unable to get tasks picklist: " + err, "is-danger");
      }
    );
  }
};
</script>
